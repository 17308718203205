import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Header=()=>{
    const navigate = useNavigate()
    const BASE_URL_WEB =  process.env.REACT_APP_WEB_URL;

    const onClickLogOut = (e) => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/')
    }


    const handleOnClickLoginLearnet = async (e) => {
 
            const data = {
                adminToken: localStorage.getItem('token'),
            }
            // Convert the data object to URL parameters
            const params = new URLSearchParams(data).toString();
            // Open the new page with the URL parameters
            window.open(`${BASE_URL_WEB}?${params}`, '_blank');
    
    }
    
    return(<>
       <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    {/* <!-- Left navbar links --> */}
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="/dashboard" role="button"><i className="fas fa-bars"></i></a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">         
          <Link to="/dashboard"  className="nav-link" >Admin Panel</Link>
      </li>

      
        <li className="nav-item ms-3">         
            <Button  className="" 
            onClick={handleOnClickLoginLearnet}
              >User Login </Button>
            
        </li>
     
    </ul>

    {/* <!-- Right navbar links --> */}
    <ul className="navbar-nav ml-auto">
        <li className="nav-item">
            {/* <!-- Sidebar user panel (optional) --> */}
            <div className="user-panel d-flex">
                <div className="image img-circle" >
                    
                    <i className='  fa fa-user-circle  mdi mdi-account mdi-24px '></i>
                </div>
                <div className="info">
                    <a href="#/dashboard" className="d-block">Admin</a>
                </div>
            </div>
        </li>
        {/* <!-- Navbar Search --> */}
        <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#/dashboard" aria-expanded="false">
                <i className="fa fa-cog"></i>               
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
               
                <div className="dropdown-divider"></div>
                <a onClick={onClickLogOut} className="dropdown-item logout">
                    Logout              
                </a>
            </div>
                
        </li>




    </ul>
  </nav>
 
   
    </>);
}
export default Header;