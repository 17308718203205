import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import APIHandler from "../../helper/APIHandler";

const GroupSessionTopicModal = ({
  isOpen,
  handleClose,
  topicData,
  onCanceled,
}) => {
  const [topic, setTopic] = useState("");
  const [topicDetails, setTopicDetails] = useState("");
  const [topicCoverage, setTopicCoverage] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [mentees, setMentees] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [disabledUpdate, setDisabledUpdate] = useState(false);

  useEffect(() => {
    setTopic(topicData?.topicName);
    setTopicDetails(topicData?.details);
    setCoverImage(topicData?.coverImage);
    if (topicData?.topicCoverages && topicData?.topicCoverages.length > 0) {
      setMentees(topicData.topicCoverages);
    } else {
      setTopicCoverage("");
    }
  }, [topicData]);

  const matchData = (e) => {
    setTopicCoverage(e.target.value);
    let test = e.target.value;
    let filword = [];
    for (var i = 0; i < test.length; i++) {
      var regex = new RegExp(test, "i");
      if (regex.test(topic)) {
        filword.push(topic);
      }
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      if (topicCoverage) {
        setMentees([...mentees, topicCoverage]);
        setTopicCoverage("");
      }
    }
  };

  const removeSkills = (name) => {
    const filteredSkills = mentees.filter((skill) => skill !== name);
    setMentees(filteredSkills);
  };

  const handleUploadCoverImage = async (e) => {
    try {
     
      const file = e.target.files[0];
      setDisabledUpdate(true);
      ///only accept image files
      if (!file.type.includes("image")) {
        setErrorMessage({
          ...errorMessage,
          coverImage: "Only image files are allowed",
        });
        return;
      }

      // if (file.size > 500000) {
      //   setErrorMessage({
      //     ...errorMessage,
      //     coverImage: "Cover image size should be less than 500kb",
      //   });
      //   return;
      // }

      if (file.size > 1000000) {
        setErrorMessage({
          ...errorMessage,
          coverImage: "Cover image size should be less than 1mb",
        });
        return;
      }
      const image = await resizeFile(e.target.files[0], 600, 450);
      const selectedFile = image;
      const selectedFileName = e.target.files[0].name;
      const formData = new FormData();
      formData.append("files", selectedFile, selectedFileName);
      const uploadCoverImageTopic = await APIHandler.post(
        `uploads?for=GroupSession`,
        formData
      );

      console.log("uploadCoverImageTopic", uploadCoverImageTopic);

      if (uploadCoverImageTopic.success === true) {
        setCoverImage(uploadCoverImageTopic.data.file_path);
        setDisabledUpdate(false);
      } else {
        toast.error(uploadCoverImageTopic.message);
      }
    } catch (error) {
      console.error("Error while uploading cover image", error);
      toast.error("Error while uploading cover image");
    }
  };

  const resizeFile = (file, width, height) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        "PNG",
        100,
        0,
        (uri) => {
          const byteString = atob(uri.split(",")[1]);
          const mimeString = uri.split(",")[0].split(":")[1].split(";")[0];
          const byteNumbers = new Array(byteString.length);
          for (let i = 0; i < byteString.length; i++) {
            byteNumbers[i] = byteString.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: mimeString });
          resolve(blob);
        },
        "base64"
      );
    });

  const handleUpdate = async () => {
    try {
      const errors = {};
      if (!topic) {
        errors.topic = "Topic name is required";
      }
      if (!topicDetails) {
        errors.topicDetails = "Topic details is required";
      }
      if (!topicDetails.trim() || topicDetails.trim() === "<p><br></p>") {
        // Check if it's empty or contains only empty HTML tags
        errors.topicDetails = "Topic details is required";
      }
      const plainTopicDetails = topicDetails.replace(/<[^>]+>/g, ""); // Remove HTML tags
      if (!plainTopicDetails.trim()) {
        // Check if the content without HTML tags is empty
        errors.topicDetails = "Topic details is required";
      }
      if (!mentees.length) {
        errors.topicCoverage = "Tag is required";
      }
      if (!coverImage) {
        errors.coverImage = "Cover image is required";
      }
      // else if (coverImage.size > 500000) {
      //   errors.coverImage = "Cover image size should be less than 500kb";
      // }
      setErrorMessage(errors);
      if (Object.keys(errors).length === 0) {
        const newTopic = {
          mentorId: topicData.mentorId,
          topicId: topicData._id,
          topicName: topic,
          details: topicDetails,
          topicCoverages: [...mentees],
          coverImage: coverImage, // Make sure to handle file upload properly
        };

        const response = await APIHandler.put(
          "admin/group-sessions/topics",
          newTopic
        );
        if (response.success === true) {
          toast.success("Topic updated successfully");
          handleClose();
          setTopic("");
          setTopicDetails("");
          setTopicCoverage("");
          setMentees([]);
          setCoverImage(null);
        }
      }
    } catch (error) {
      console.error("Error while saving topic", error);
      toast.error(error.response.data.message || "Error while saving topic");
    }
  };

  return (
    <Modal show={isOpen} onHide={onCanceled} centered size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="modalLabelCreateFeed">
            Edit Topic
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onCanceled}
          />
        </div>

        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <div className="form-group mb-3">
                <label htmlFor="topicName" className="form-label">
                  Topic Name*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="topicName"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  onFocus={(e) =>
                    setErrorMessage({ ...errorMessage, topic: "" })
                  }
                />
                <div className="text-danger">{errorMessage.topic}</div>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="topicDetails" className="form-label">
                  Topic Details*
                </label>
                <div style={{ height: "243px" }}>
                  <ReactQuill
                    value={topicDetails}
                    onChange={setTopicDetails}
                    style={{ height: "200px" }}
                    onFocus={(e) =>
                      setErrorMessage({ ...errorMessage, topicDetails: "" })
                    }
                  />
                </div>
                <div className="text-danger">{errorMessage.topicDetails}</div>
              </div>

              <div className="form-group mb-3">
                <div className="d-flex">
                  <label htmlFor="topicCoverage" className="form-label">
                    Tags*
                  </label>{" "}
                  <span className="form-text text-muted ">
                    (Separate each tag or skill with a comma)
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="topicCoverage"
                  value={topicCoverage}
                  onChange={matchData}
                  onKeyDown={_handleKeyDown}
                  onFocus={(e) =>
                    setErrorMessage({ ...errorMessage, topicCoverage: "" })
                  }
                />

                {mentees.length > 0 && (
                  <div className="skills">
                    <div className="filter-header">
                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{ minHeight: "40px" }}
                        >
                          {mentees &&
                            mentees.map((val, key) => {
                              return (
                                <span key={key} className="filter-list">
                                  {val.length > 20
                                    ? val.substr(0, 20) + "..."
                                    : val}{" "}
                                  <img
                                    className="mt-1"
                                    onClick={(e) => removeSkills(val)}
                                    src="images/cancel.png"
                                    alt="Remove"
                                    
                                  />
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="text-danger">{errorMessage.topicCoverage}</div>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="coverImage" className="form-label">
                  Cover Image*
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="coverImage"
                  onChange={(e) => handleUploadCoverImage(e)}
                  onFocus={(e) =>
                    setErrorMessage({ ...errorMessage, coverImage: "" })
                  }
                />
                <div className="text-danger">{errorMessage.coverImage}</div>
              </div>

              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleUpdate}
                  disabled={disabledUpdate}
                >
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-primary-soft btn-sm ms-2"
                  onClick={onCanceled}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GroupSessionTopicModal;
