import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../layout/layout";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "../layout/Pagination";
import { Modal, Button, Form } from "react-bootstrap";
import AddArticle from "./AddArticle";
import "./HelpCenter.css";
import UpdateArticle from "./UpdateArticle";



const BASE_URL = process.env.REACT_APP_API_URL;

let PageSize = 10;

const Article = () => {
  const [modal, setModal] = useState(false);
  const [article, setArticle] = useState([]);
  const [isLoaded, SetisLoaded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [documentId, setDocumentId] = useState("");
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [articleId, setArticalId] = useState("");
  const navigate = useNavigate()

    useEffect(() => {

        if(localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == ""){
            navigate('/')
        }
    },[] )

  const { id } = useParams();

  const getArticle = async () => {
    SetisLoaded(false);
    const data = await axios.get(
      `${BASE_URL}topics/getSigleTopicArticles/${id}`
    );
    setArticle(data.data.data);
    SetisLoaded(true);
    // console.log(data.data.data)
  };

  const hendelDelete = async (dataId) => {
    const data = await axios.delete(
      `${BASE_URL}topics/deleteTopicArticles/${dataId}`
    );
    if (data.data.success === true) {
      toast.success("Article Delete Successfully");
      getArticle();
      toggle();
    } else {
      toast.error("Something went wrong");
    }
  };

  const toggle = () => {
    setModal(!modal);
  };
  const openModalWithHeaderClass = (id) => {
    setDocumentId(id);
    toggle();
  };

  useEffect(() => {
    getArticle();
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return article.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, article]);

  return (
    <>
      <Layout />
      <div className="content-wrapper my_wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Help Center</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  className="breadcrumb-item active"
                  onClick={() => navigate(-1)}
                >
                  Help Center
                </li>
                <li className="breadcrumb-item active">Article</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary" onClick={() => setShow(true)}>
            Add Article{" "}
          </button>
          &nbsp;
          <Link className="btn btn-primary" to={"/help-center"}> Back </Link>
        </div>
        <section>
          <div className="row">
            <div className="col-lg-12">
              <div className="card card-primary mt-4">
                <div className="revenue_card_header">
                  <h3>Article</h3>
                </div>
                <div className="card-body">
                  <table className="table table-striped  table-bordered">
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Article</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>

                    <tbody>
                      {isLoaded ? (
                        currentTableData !== null &&
                          currentTableData.length > 0 ? (
                          currentTableData.map((obj, index) => (
                            <tr key={obj._id}>
                              <td>{obj.S_No}</td>
                              <td>{obj.titleName}</td>
                              <td>
                                <Link
                                  onClick={() => {
                                    // setArticleObj()
                                    // console.log(obj, "obj");
                                    setEditShow(true);
                                    setArticalId(obj);
                                  }}
                                >
                                  Edit
                                </Link>
                              </td>
                              <td>
                                <Link
                                  onClick={() =>
                                    openModalWithHeaderClass(obj._id)
                                  }
                                >
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>No data found</td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            {" "}
                            <div className="loader"></div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={article.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          className="add-article-model"
          show={show}
          onHide={() => setShow(false)}
        >
          <AddArticle setShow={setShow} getArticle={getArticle} />
        </Modal>
        <Modal
          className="add-article-model"
          show={editShow}
          onHide={() => setEditShow(false)}
        >
          <UpdateArticle
            articleId={articleId}
            setEditShow={setEditShow}
            getArticle={getArticle}
          />
        </Modal>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal show={modal} onHide={toggle}>
        <Modal.Header onHide={toggle}>
          <h4 className="modal-title">Delete Article</h4>
        </Modal.Header>
        <Modal.Body>
          <h5 className="mt-0">Are you sure ?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={toggle}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => hendelDelete(documentId)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Article;
