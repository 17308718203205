import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { CKEditor } from "ckeditor4-react";
import "./HelpCenter.css";
import { useNavigate } from "react-router";

const BASE_URL =process.env.REACT_APP_API_URL;

const UpdateArticle = ({ articleId, setEditShow, getArticle }) => {

  const navigate = useNavigate()

  useEffect(() => {

      if(localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == ""){
          navigate('/')
      }
  },[] )

  const { id } = useParams();

  const [title, setTitle] = useState(articleId.titleName);
  const [sortDes, setSordDes] = useState(articleId.short_Description);
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState(articleId.tags);
  const [value, setValue] = useState(base64_decode(articleId.Long_Description));

  const handleChange = (evt) => {
    setValue(evt.editor.getData());
  };
  // console.log(sortDes, "sortDes");
  const handleUpdate = async () => {
    if (!title) {
      toast.error("Please Enter Article Title");
    } else if (!sortDes) {
      toast.error("Please Enter Article Sort Description");
    } else if (!value) {
      toast.error("Please Enter Article Long Description");
    } else {
      const data = await axios.put(
        `${BASE_URL}topics/updateTopicArticles/${articleId._id}`,
        {
          titleName: title,
          short_Description: sortDes,
          Long_Description: base64_encode(value),
          topic_Id: id,
          tags: tags,
        }
      );
      // console.log(data)
      if (data.data.success === true) {
        setEditShow(false);
        getArticle();
        toast.success("Article Updated Successfully");
      }
    }
  };

  return (
    <>
      <Form>
        <Modal.Header>
          <Modal.Title className="w-100 text-center">
            Update Article
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Label>Article Title</Form.Label>
            <Form.Control
              className="w-75 mb-3"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <Form.Label>Short Description</Form.Label>
            <Form.Control
              className="mb-3"
              as="textarea"
              value={sortDes}
              onChange={(e) => setSordDes(e.target.value)}
            />
          </div>
          <Form.Label>Long Description</Form.Label>
          <CKEditor value={value} initData={value} onChange={handleChange} />
          <div className="mt-3">
            <Form.Label>Add Tags</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                className="w-25"
                type="text"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
              <span
                className="add-tag-btn"
                onClick={() => (
                  tags.includes(tag)
                    ? null
                    : tag === ""
                    ? null
                    : setTags((prevState) => [...prevState, tag]),
                  setTag("")
                )}
              >
                +
              </span>
            </div>
            <div className="tags-box">
              {tags.map((tag, index) => (
                <p key={index}>
                  {tag}
                  <span
                    onClick={() =>
                      setTags((prevState) =>
                        prevState.filter((value) => value !== tag)
                      )
                    }
                  ></span>
                </p>
              ))}
            </div>
          </div>
        </Modal.Body>
        <div className="ml-3 mb-2 d-flex justify-content-start">
          <button
            className="mr-3 btn btn-primary"
            onClick={() => setEditShow(false)}
            type="button"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            type="submit"
            onClick={() => handleUpdate()}
          >
            Update
          </button>
        </div>
      </Form>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default UpdateArticle;
